import React from "react";
import Link from "next/link";
import clsx from "clsx";
import { ChevronRight } from "lucide-react";
import noop from "lodash/noop";

export type BreadcrumbItemType = {
  title: string;
  link: string;
};

const Breadcrumb = ({
  items,
  className,
  disabledLastLink = true,
  onLinkClick = noop,
  enableTextWrap = false,
}: {
  items: BreadcrumbItemType[];
  className?: string;
  disabledLastLink?: boolean;
  onLinkClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  enableTextWrap?: boolean;
}) => {
  if (!items?.length) return null;
  return (
    <nav className={clsx("flex text-sm", className)} data-testid="Breadcrumb" aria-label="Breadcrumb">
      <ol className={clsx("line-clamp-1 inline-flex", enableTextWrap ? "items-start" : "items-center")}>
        {items.map((item: BreadcrumbItemType, index: number) => (
          <li key={item.title} className={clsx("inline-flex", enableTextWrap ? "items-start" : "items-center")}>
            <div
              className={clsx(
                "inline-flex shrink-0 items-center",
                enableTextWrap ? "p-1" : "px-1",
                index === 0 && "w-[0px] !p-0"
              )}
            >
              <ChevronRight className="h-3 w-3 fill-[#6b6f76] dark:fill-[#f2f2f2]" />
            </div>
            {index === items.length - 1 && disabledLastLink ? (
              <span
                className={clsx(
                  "inline-flex items-center font-medium text-base-disabled dark:text-base-dark-disabled",
                  !enableTextWrap && "whitespace-nowrap"
                )}
              >
                {item.title}
              </span>
            ) : (
              <Link
                href={item.link}
                className={clsx(
                  "inline-flex items-center  font-medium text-base-disabled hover:underline dark:text-base-dark-disabled",
                  !enableTextWrap && "whitespace-nowrap"
                )}
                onClick={onLinkClick}
              >
                {item.title}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
