import { useMutation } from "@tanstack/react-query";
import { PostgrestError } from "@supabase/supabase-js";

import useErrorLogger from "hooks/useErrorLogger";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { deleteRecordById } from "lib/supabaseApi";
import type { DeleteInput, DeleteResponse } from "types/apiTypes";
import { ERROR_TYPES } from "utils/constants";

const useDeleteRecord = () => {
  const supabaseClient = useSupabaseBrowser();
  const { logError } = useErrorLogger();
  const { mutateAsync, isPending } = useMutation<DeleteResponse, unknown, DeleteInput>({
    mutationFn: ({ tableName, id }: DeleteInput) => deleteRecordById({ tableName, id }, supabaseClient),
    onError: (error, variables) => {
      logError({
        error: error as PostgrestError,
        source: "useDeleteRecord",
        type: ERROR_TYPES.HOOKS,
        message: (error as PostgrestError).message || "Error adding record",
        url: window.location.href,
        additionalInfo: {
          variables
        }
      });
    }
  });

  return {
    deleteRecordAsync: mutateAsync,
    isLoading: isPending
  };
};

export default useDeleteRecord;
