import { createClient } from "@supabase/supabase-js";

import { ALTERNATE_SCHEMAS } from "utils/constants";
import { RecordItem } from "types/common";

import { supabaseUrl, supabaseAnonKey } from "../utils/supabase";
import useCurrentUser from "./useCurrentUser";

const supabaseAuditClient =
  supabaseUrl && supabaseAnonKey
    ? createClient(supabaseUrl || "", supabaseAnonKey || "", {
        db: {
          schema: ALTERNATE_SCHEMAS.AUDIT
        },
        auth: {
          autoRefreshToken: false,
          persistSession: false
        }
      })
    : undefined;

const supabaseSegmentCleanClient =
  supabaseUrl && supabaseAnonKey
    ? createClient(supabaseUrl || "", supabaseAnonKey || "", {
        db: {
          schema: ALTERNATE_SCHEMAS.SEGMENT_CLEAN
        },
        auth: {
          autoRefreshToken: false,
          persistSession: false
        }
      })
    : undefined;

const schemaClientMap: RecordItem = {
  [ALTERNATE_SCHEMAS.AUDIT]: supabaseAuditClient,
  [ALTERNATE_SCHEMAS.SEGMENT_CLEAN]: supabaseSegmentCleanClient
};
const useSupabaseAltClient = () => {
  const currentUser = useCurrentUser();

  return currentUser?.id ? schemaClientMap : undefined;
};

export default useSupabaseAltClient;
