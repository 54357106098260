import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";

import sortBy from "lodash/sortBy";
import { useRouter } from "next/navigation";
import useAddRecord from "hooks/useAddRecord";
import useCurrentUser from "hooks/useCurrentUser";
import useDeleteRecord from "hooks/useDeleteRecord";
import useInLayout from "hooks/useInLayout";
import useInviteUser from "hooks/useInviteUser";
import useLockRecord from "hooks/useLockRecord";
import useNestedViewState from "hooks/useNestedViewState";
import useNotificationsState from "hooks/useNotificationsState";
import usePageByPath from "hooks/usePageByPath";
import useRemoveRecord from "hooks/useRemoveRecord";
import useSchemaState from "hooks/useSchemaState";
import useSendToAction from "hooks/useSendToAction";
import useTableActionsState from "hooks/useTableActionsState";
import useTableDataById from "hooks/useTableDataById";
import useUpdateRecordByCompositeKey from "hooks/useUpdateRecordByCompositeKey";

import { buildSupabasePublicUrl } from "components/Image/utils";
import { NOTIFICATION_TYPE } from "components/Notifications/utils";
import { getCompositeKeyFromRecord } from "lib/utils";
import { User } from "types/apiTypes";
import { ViewAction } from "types/baTypes";
import { RecordItem } from "types/common";
import { getTimeStamp } from "utils/columnUtils";
import {
  APP_QUERY_PARAM_TYPES,
  ActionType,
  AdditionalFormActions,
  CellType,
  CrudActions,
  STATIC_SIDEBAR_IDS,
  SidebarContainer,
  TOAST_TYPE,
  UI_ACTIONS,
  ViewOption
} from "utils/constants";
import { hasAFileTable, hasANoteTable } from "utils/dataUtils";
import { downloadFileFromSupabase } from "utils/file";
import { getRecordInNestedView } from "utils/pageUtils";
import { copyText } from "utils/texts";
import toast, { ToastId } from "utils/toast";
import useFinalRecordHeaderMap from "./useFinalRecordHeaderMap";
import useSearchQueryParams from "./useSearchQueryParams";
import useUpdateRecord from "./useUpdateRecord";

// Handles all common page actions
// Does not handle Admin actions that should be handled by the component
const useActionsHandler = ({
  tableName,
  onActionSuccessCallback,
  tablePath,
  inView,
  isInDetailOverviewHeader,
  onActionsClick,
  record,
  isBulkAction
}: {
  tableName: string;
  onActionSuccessCallback?: (editedRow: RecordItem, actionName: UI_ACTIONS) => void;
  parentRecordSlug?: string;
  tablePath?: string;
  inView?: ViewOption;
  isInDetailOverviewHeader?: boolean;
  onActionsClick?: (action: ViewAction, row: RecordItem) => void;
  record?: RecordItem;
  isBulkAction?: boolean;
}) => {
  const progressToastId = useRef<ToastId | null>(null);

  const queryClient = useQueryClient();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const {
    updateSidebarState,
    updateTriggerAction,
    currentProjectId,
    detailTabViewFilterByTableSlug,
    currentRecordId,
    currentRecordTableName
  } = useTableActionsState();
  const { schemaInstance } = useSchemaState();
  const { addPendingNotification } = useNotificationsState();
  const { inviteUser, sendMagicLink } = useInviteUser();
  const { updateRecordByCompositeKeyAsync } = useUpdateRecordByCompositeKey();
  const { removeRecordAsync } = useRemoveRecord();
  const { deleteRecordAsync } = useDeleteRecord();
  const { nestedViewStack } = useNestedViewState();
  const { lockRecordAtTableLevel } = useLockRecord();
  const { addRecordAsync } = useAddRecord();
  const { updateRecordAsync } = useUpdateRecord();
  const { isTable, isRecord } = useInLayout();
  const { setParams } = useSearchQueryParams();
  const { sendToTopAction, sendToBottomAction } = useSendToAction(tablePath?.replace("/", "") || "", tableName);

  const [finalRecordId, setFinalRecordId] = useState<string>();
  const [currentAction, setCurrentAction] = useState<ViewAction>();

  const compositePrimaryKey = tableName ? schemaInstance?.extendedSchema[tableName]?.compositePk : undefined;

  const { data: currentTablePage } = usePageByPath(
    { slug: tablePath?.replace("/", "") || "" },
    { enabled: !!tablePath }
  );

  // This is fetching all the base columns of the record to duplicate
  const { data: recordData } = useTableDataById(
    {
      slug: tablePath?.replace("/", "") || "",
      tableName,
      id: finalRecordId || "",
      columns: [],
      source: "actionsHandler"
    },
    {
      enabled: !!finalRecordId
    }
  );

  const { finalRecord, colHeaderTypeMap } = useFinalRecordHeaderMap(
    currentTablePage ?? undefined,
    record || recordData
  );

  const peopleColForEmailAction = useMemo(
    () =>
      sortBy(
        (currentTablePage?.columns || [])?.filter((col) => col.type === CellType.PEOPLE),
        (col) => col.views?.[ViewOption.DETAIL_MAIN]?.sort
      )?.[0],
    [currentTablePage?.columns]
  );

  const prefillValueForEmailAction = useMemo(() => {
    const prefillValues: RecordItem = {};
    if (peopleColForEmailAction && finalRecord) {
      const selectedColKey = Object.keys(colHeaderTypeMap).find(
        (key) => colHeaderTypeMap[key].column.id === peopleColForEmailAction.id
      );

      if (selectedColKey) {
        const value = Array.isArray(finalRecord?.[selectedColKey])
          ? finalRecord?.[selectedColKey]?.[0]
          : finalRecord?.[selectedColKey];
        if (value) {
          prefillValues["to_person"] = { displayValue: { id: value?.id }, inputValue: { id: value?.id } };
        }
      }
    }
    return prefillValues;
  }, [peopleColForEmailAction, colHeaderTypeMap, finalRecord]);

  const sendWebhookWithNotification = useCallback(
    ({
      action,
      notificationId,
      newRecordFromForm
    }: {
      action: ViewAction;
      notificationId: string;
      newRecordFromForm?: RecordItem;
    }) => {
      if (!action.webhookURL || !tableName) return;
      const timestamp = getTimeStamp();

      fetch(action.webhookURL, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          action: action?.name,
          actionId: action?.ui_action_id?.id,
          recordId: record?.id,
          tableName,
          userId: currentUser?.user_id,
          timestamp,
          action_props: {
            id: notificationId,
            currentTablePage: tablePath,
            newRecordFromSidebar: newRecordFromForm,
            projectId: currentProjectId,
            parentRecord: !!action?.skipParentRecord ? undefined : record
          }
        })
      });
      addPendingNotification({
        id: notificationId,
        action: action.name,
        recordId: record?.id,
        tableName,
        userId: currentUser?.user_id || "",
        action_props: {
          id: notificationId,
          currentTablePage: tablePath,
          hasWaitForSuccess: action?.waitForSuccess,
          newRecordFromSidebar: newRecordFromForm,
          projectId: currentProjectId
        },
        onSuccessCallback: action?.onSuccessCallback,
        type: NOTIFICATION_TYPE.ACTION
      });
    },
    [currentUser, addPendingNotification, currentProjectId, record, tablePath, tableName]
  );

  const handleDeleteRecord = useCallback(
    async ({ id, record }: { id: string; record?: RecordItem }) => {
      progressToastId.current = toast.success("Deleting record...", {
        autoClose: false
      });
      let deleteResp;
      if (compositePrimaryKey?.length && record) {
        const compositeKey = getCompositeKeyFromRecord(record, compositePrimaryKey);
        deleteResp = await removeRecordAsync({ tableName: tableName as string, id, compositePrimaryKey: compositeKey });
      } else {
        deleteResp = await deleteRecordAsync({ tableName: tableName as string, id });
      }

      if (deleteResp?.status === 204 && record) {
        toast.update(progressToastId.current, "Record deleted!", {
          type: TOAST_TYPE.SUCCESS
        });
        onActionSuccessCallback?.(record, UI_ACTIONS.DELETE);
      } else {
        toast.update(progressToastId.current, "Error deleting record", {
          type: TOAST_TYPE.ERROR
        });
      }
      queryClient.invalidateQueries({ queryKey: ["table", tableName] });
    },
    [tableName, deleteRecordAsync, queryClient, compositePrimaryKey, removeRecordAsync, onActionSuccessCallback]
  );

  const handleAddRecord = useCallback(
    async (input: RecordItem) => {
      if (!tableName) return;
      progressToastId.current = toast.success("Creating duplicate record...", {
        autoClose: false
      });

      const newRecordResp = await addRecordAsync({
        tableName,
        input
      });

      if (newRecordResp?.status === 201) {
        toast.update(progressToastId.current, "Record created!", {
          type: TOAST_TYPE.SUCCESS
        });
        queryClient.invalidateQueries({ queryKey: ["table", tableName] });
        if (currentAction?.webhookURL) {
          const notificationId = v4();
          const timestamp = getTimeStamp();
          fetch(currentAction?.webhookURL, {
            method: "POST",
            mode: "no-cors",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              newRecordId: newRecordResp?.data?.[0].id,
              originalRecordId: finalRecordId,
              tableName,
              userId: currentUser?.user_id,
              actionName: currentAction?.name,
              actionId: currentAction?.ui_action_id?.id,
              timestamp,
              action_props: {
                id: notificationId,
                duplicateId: newRecordResp?.data?.[0].id,
                currentTablePage: tablePath,
                projectId: currentProjectId
              }
            })
          });
          addPendingNotification({
            id: notificationId,
            recordId: newRecordResp?.data?.[0].id,
            tableName,
            userId: currentUser?.user_id || "",
            action: currentAction?.name,
            action_props: {
              id: notificationId,
              duplicateId: newRecordResp?.data?.[0].id,
              currentTablePage: tablePath,
              projectId: currentProjectId
            },
            onSuccessCallback: currentAction?.onSuccessCallback,
            type: NOTIFICATION_TYPE.ACTION
          });
        }
      } else {
        toast.update(progressToastId.current, "Error creating record", {
          type: TOAST_TYPE.ERROR
        });
      }
      setCurrentAction(undefined);
      setFinalRecordId(undefined);
    },
    [
      addRecordAsync,
      tableName,
      queryClient,
      currentAction,
      finalRecordId,
      currentUser,
      addPendingNotification,
      tablePath,
      currentProjectId
    ]
  );

  const handleDeleteFilesListFiles = useCallback(
    async ({ filesListId, fileId }: { filesListId: string; fileId: string }) => {
      if (!filesListId || !fileId) return;
      progressToastId.current = toast.success("Deleting record...", {
        autoClose: false
      });
      const deleteResp = await removeRecordAsync({
        tableName: "files_lists_files",
        compositePrimaryKey: {
          files_lists_id: filesListId,
          files_id: fileId
        }
      });

      if (deleteResp?.status === 204) {
        toast.update(progressToastId.current, "Record deleted!", {
          type: TOAST_TYPE.SUCCESS
        });
      } else {
        toast.update(progressToastId.current, "Error deleting record", {
          type: TOAST_TYPE.ERROR
        });
      }
      queryClient.invalidateQueries({ queryKey: ["table", tableName] });
    },
    [removeRecordAsync, queryClient, tableName]
  );

  const handleViewAction = useCallback(
    ({
      action,
      bulkSelectedRowIds,
      actionSource
    }: {
      action: ViewAction;
      bulkSelectedRowIds?: string[];
      actionSource?: string;
    }) => {
      const addlProps: RecordItem = {};
      if (isBulkAction && bulkSelectedRowIds?.length) {
        addlProps.bulkSelectedRowIds = bulkSelectedRowIds;
      }
      if (action.action_properties?.isPageViewLinked) {
        updateSidebarState(
          {
            isOpen: true,
            action: CrudActions.CREATE,
            record,
            tablePath,
            showDetailView: false,
            skiploadSidebaronSubmission: true,
            pageFormViewId: action.viewId,
            onActionComplete:
              action?.webhookURL && record?.id
                ? (newRecord?: RecordItem) => {
                    const notificationId = v4();
                    sendWebhookWithNotification({
                      action,
                      notificationId,
                      newRecordFromForm: newRecord
                    });
                  }
                : undefined,
            additionalProps: addlProps,
            isBulkAction,
            actionSource
          },
          STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
        );
        return;
      }

      // We need to display the view (currently only Form)
      updateSidebarState(
        {
          isOpen: true,
          action: CrudActions.CREATE_CUSTOM,
          record,
          formViewId: action.viewId,
          showDetailView: false,
          skiploadSidebaronSubmission: true,
          container: action.sidebarPage?.display,
          onActionComplete:
            action?.webhookURL && record?.id
              ? (newRecord?: RecordItem) => {
                  const notificationId = v4();
                  sendWebhookWithNotification({
                    action,
                    notificationId,
                    newRecordFromForm: newRecord
                  });
                }
              : undefined,
          additionalProps: addlProps,
          isBulkAction,
          actionSource
        },
        STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
      );
    },
    [record, updateSidebarState, sendWebhookWithNotification, tablePath, isBulkAction]
  );

  const handleSidebarAction = useCallback(
    ({
      action,
      bulkSelectedRowIds,
      actionSource
    }: {
      action: ViewAction;
      bulkSelectedRowIds?: string[];
      actionSource?: string;
    }) => {
      if (!action.sidebarPage?.id) {
        return;
      }
      const addlProps: RecordItem = {};
      if (isBulkAction && bulkSelectedRowIds?.length) {
        addlProps.bulkSelectedRowIds = bulkSelectedRowIds;
      }
      updateSidebarState(
        {
          isOpen: true,
          action: CrudActions.CREATE,
          record,
          tablePath: action.sidebarPage?.path,
          title: action.sidebarPage?.title,
          pageFormViewId: action.sidebarPage?.formViewId,
          showDetailView: false,
          skiploadSidebaronSubmission: true,
          container: action.sidebarPage?.display as SidebarContainer,
          onActionComplete:
            action?.webhookURL && record?.id
              ? (newRecord?: RecordItem) => {
                  const notificationId = v4();
                  sendWebhookWithNotification({
                    action,
                    notificationId,
                    newRecordFromForm: newRecord
                  });
                }
              : undefined,
          additionalProps: addlProps,
          isBulkAction,
          actionSource
        },
        STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
      );
    },
    [record, updateSidebarState, sendWebhookWithNotification, isBulkAction]
  );

  const handleOpenRecordInCollapsibleSidebar = useCallback(
    (recordToOpen: RecordItem) => {
      if (currentTablePage?.id) {
        setParams({
          cellSide: {
            pageId: currentTablePage?.id,
            recordId: recordToOpen.id,
            type: SidebarContainer.Collapsible,
            title: `${currentTablePage?.title ? currentTablePage?.title : "record"}-${recordToOpen.id}`
          }
        });
      }
    },
    [currentTablePage?.id, setParams, currentTablePage?.title]
  );

  const handleActionClick = useCallback(
    async ({
      action,
      formattedRecord,
      bulkSelectedRowIds,
      actionSource
    }: {
      action: ViewAction;
      formattedRecord?: RecordItem;
      bulkSelectedRowIds?: string[];
      actionSource?: string;
    }) => {
      if (action.type === ActionType.REGEN_THUMBNAIL && record?.id) {
        updateTriggerAction(ActionType.REGEN_THUMBNAIL, record.id);
        return;
      }

      if (action.viewId) {
        handleViewAction({ action, bulkSelectedRowIds, actionSource });
        return;
      }
      if (action.sidebarPage?.id) {
        handleSidebarAction({ action, bulkSelectedRowIds, actionSource });
        return;
      }

      if (action.name === UI_ACTIONS.EDIT_TILE_COMPONENTS && record?.id) {
        window.open(`/admin/tiles/${record?.id}`, "_blank");
      }

      if (action.name === UI_ACTIONS.INVITE && record?.id) {
        inviteUser((formattedRecord || record) as User);
        return;
      }
      if (action.name === UI_ACTIONS.DELETE && record?.id && tablePath) {
        if (
          detailTabViewFilterByTableSlug?.[tablePath.replace("/", "")]?.find(
            (filter) => filter?.filterLookupPath?.["0"]?.lookupTableName === "files_lists_files"
          ) &&
          record?.id
        ) {
          const filesListFilter = detailTabViewFilterByTableSlug?.[tablePath.replace("/", "")]?.find(
            (filter) => filter?.filterLookupPath?.["0"]?.lookupTableName === "files_lists_files"
          );
          const filesListId = filesListFilter?.filterValue as string;
          if (filesListId) {
            // We need to remove the files_lists_files record in this case
            handleDeleteFilesListFiles({ filesListId, fileId: record?.id });
          }
          return;
        }

        handleDeleteRecord({ id: record.id, record });
        return;
      }
      if (action.name === UI_ACTIONS.DUPLICATE && record?.id && tableName) {
        const tableProps = schemaInstance?.extendedSchema?.[tableName];
        // Check if table is join table
        if (tableProps?.compositePk?.length) {
          progressToastId.current = toast.success("Duplicating record...");
          if (progressToastId.current) {
            toast.update(progressToastId.current, "Cannot duplicate record in this table", {
              type: TOAST_TYPE.ERROR,
              autoClose: 2000
            });
          }
          return;
        }
        setCurrentAction(action);
        // Requires fetching all direct fields for record first
        setFinalRecordId(record.id);
      }
      if (action.name === UI_ACTIONS.EMAIL && tableName) {
        updateSidebarState(
          {
            isOpen: true,
            action: AdditionalFormActions.EMAIL,
            record,
            columns: [],
            tablePath: "emails",
            title: "Email",
            showDetailView: false,
            prefillValues: prefillValueForEmailAction
          },
          STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
        );
      }

      if (action.name === UI_ACTIONS.OPEN_IN_SIDEBAR && record?.id) {
        handleOpenRecordInCollapsibleSidebar(record);
      }

      if (action.name === UI_ACTIONS.FEATURED && record?.id && tablePath) {
        if (currentRecordId && currentRecordTableName && record?.id) {
          // Check if current record table name has `featured_file_id` column
          const hasFeaturedFileIdColumn =
            !!schemaInstance?.extendedSchema?.[currentRecordTableName]?.properties?.["featured_file_id"]?.type;
          if (hasFeaturedFileIdColumn) {
            progressToastId.current = toast.success("Setting image as featured...");
            const updatedRecord = await updateRecordAsync({
              tableName: currentRecordTableName,
              input: {
                id: currentRecordId,
                featured_file_id: record.id
              }
            });

            if (!updatedRecord?.error) {
              toast.update(progressToastId.current, "Image set as featured!", {
                type: TOAST_TYPE.SUCCESS
              });
              queryClient.invalidateQueries({ queryKey: ["table", tableName] });
              if (isRecord) {
                // invalidate record to update featured image in detail title control header
                router.refresh();
              }
              onActionSuccessCallback?.(record, UI_ACTIONS.FEATURED);
            } else {
              toast.update(progressToastId.current, "Image update failed!", {
                type: TOAST_TYPE.ERROR
              });
            }
            return;
          }
        }

        // Check if current table is a join table with file_lists_files filter
        if (
          detailTabViewFilterByTableSlug?.[tablePath.replace("/", "")]?.find(
            (filter) => filter?.filterLookupPath?.["0"]?.lookupTableName === "files_lists_files"
          ) &&
          record?.id
        ) {
          progressToastId.current = toast.success("Setting image as featured...");
          const filesListFilter = detailTabViewFilterByTableSlug?.[tablePath.replace("/", "")]?.find(
            (filter) => filter?.filterLookupPath?.["0"]?.lookupTableName === "files_lists_files"
          );
          const filesListId = filesListFilter?.filterValue as string;
          if (filesListId && record?.id && tableName === "files") {
            const input: RecordItem = {
              is_featured: true,
              compositePrimaryKey: {
                files_lists_id: filesListId,
                files_id: record.id
              }
            };

            const updatedRecord = await updateRecordByCompositeKeyAsync({
              tableName: "files_lists_files",
              input
            });

            if (!updatedRecord?.error) {
              toast.update(progressToastId.current, "Image set as featured!", {
                type: TOAST_TYPE.SUCCESS
              });
              queryClient.invalidateQueries({ queryKey: ["table", tableName] });
              if (isRecord) {
                // invalidate record to update featured image in detail title control header
                router.refresh();
              }
              onActionSuccessCallback?.(record, UI_ACTIONS.FEATURED);
            } else {
              toast.update(progressToastId.current, "Image update failed!", {
                type: TOAST_TYPE.ERROR
              });
            }
          }
        }
      }

      // if either path is present or icon val is present(icon only for spaces for now and as path to storageUrl)
      if (action.name === UI_ACTIONS.COPY_RAW_URL && (formattedRecord?.path || formattedRecord?.icon)) {
        copyText(buildSupabasePublicUrl(formattedRecord?.path || formattedRecord?.icon));
        toast.success("Copied Raw URL successfully...", {
          autoClose: 2000
        });
      }

      if (action.name === UI_ACTIONS.COPYURL && record?.id && tablePath) {
        // Check if this is a nested view
        const nestedViewRecordProps = getRecordInNestedView(nestedViewStack, currentTablePage || undefined);
        const isFilesPage = hasAFileTable(tableName, schemaInstance?.extendedSchema);
        const isNotesPage = hasANoteTable(tableName, schemaInstance?.extendedSchema);
        const pathname = window.location.pathname;
        const pathParts = pathname.split("/").filter(Boolean);
        const currentViewConfig = inView
          ? currentTablePage?.views?.find((view) => view.viewType === inView)
          : undefined;
        if (
          (nestedViewRecordProps?.isNestedView || (pathname.includes("r/") && pathParts.length > 3)) &&
          !isFilesPage
        ) {
          let finalURL = `${window.location.origin}${window.location.pathname}`;
          if (!currentViewConfig?.disableExpand) {
            finalURL += `/${record.id}`;
          }
          finalURL += window.location.search;
          copyText(finalURL);
        } else if (isNotesPage) {
          //  if notes page copy url to the record
          const url = `${window.location.origin}/r${tablePath}/${record.id}`;
          copyText(url);
        } else if (isFilesPage) {
          // Add file col id to params if present
          const featuredImgCol = currentTablePage?.columns?.find(
            (col) => col.views?.[inView || ViewOption.CARD]?.isFeaturedImage
          )?.id;
          const fileColId = currentTablePage?.columns?.find(
            (col) =>
              col.name === "path" ||
              (featuredImgCol && col.id === featuredImgCol) ||
              (col.type === "File" && col.isLookup)
          )?.id;
          let finalURL = `${window.location.origin}${window.location.pathname}`;
          if (!isTable && !currentViewConfig?.disableExpand) {
            finalURL += `/${record.id}`;
          }
          if (fileColId) {
            finalURL = `${finalURL}?${APP_QUERY_PARAM_TYPES.SIDEBAR_FILE}=${fileColId}`;
            if (isTable) {
              finalURL += `&${APP_QUERY_PARAM_TYPES.SIDEBAR_RECORD}=${record.id}&${APP_QUERY_PARAM_TYPES.SIDEBAR_PAGE}=${currentTablePage.id}`;
            }
          }
          copyText(finalURL);
        } else if (isInDetailOverviewHeader) {
          copyText(window.location.href);
        } else {
          const isProjectsPage = tableName === "projects";
          const url = `${window.location.origin}/r${isProjectsPage ? "/projects" : tablePath}/${record.id}`;
          copyText(url);
        }
        toast.success("Copied URL successfully...", {
          autoClose: 2000
        });
      }
      if (action.name === UI_ACTIONS.SEND_MAGIC_LINK && record?.id) {
        sendMagicLink({ user: (formattedRecord || record) as User });
      }
      // If the action has a webhookURL, send a POST request
      if (action.name && !["Duplicate"].includes(action.name) && action.webhookURL && record?.id) {
        const notificationId = v4();
        sendWebhookWithNotification({ action, notificationId });
      }
      if (action.name === UI_ACTIONS.DOWNLOAD && formattedRecord?.path) {
        downloadFileFromSupabase(formattedRecord.path, formattedRecord.name);
      }

      if (action.type === ActionType.SIDEBAR_AUDIT) {
        if (tableName && record?.id) {
          updateSidebarState(
            {
              showAuditSidebar: true,
              auditState: { tableName: tableName, record: record, isOpen: true }
            },
            STATIC_SIDEBAR_IDS.AUDIT_SIDEBAR
          );
        }
      }

      if (action.type === ActionType.LOCK && record?.id && tableName) {
        await lockRecordAtTableLevel(record, tableName);
        action.onSuccessCallback?.();
      }

      if (action.name === UI_ACTIONS.SEND_TO_TOP) {
        if (record?.id) {
          sendToTopAction([record]);
        }
      }
      if (action.name === UI_ACTIONS.SEND_TO_BOTTOM) {
        if (record?.id) {
          sendToBottomAction([record]);
        }
      }
      if (action.name === UI_ACTIONS.SET_DEFAULT_TABLE_PAGE && record?.id) {
        if (tableName === "pages") {
          await updateRecordAsync({
            tableName: "pages",
            input: {
              id: record.id,
              is_default_table_page: true
            }
          });
          toast.success("Default table page set successfully.", {
            autoClose: 2000
          });
        } else {
          toast.error("This action is only available for pages table");
        }
      }
      // if action handler present use it for the actions not handled here
      if (onActionsClick && record) {
        onActionsClick(action, record);
      }
    },
    [
      inviteUser,
      tableName,
      tablePath,
      schemaInstance?.extendedSchema,
      handleDeleteRecord,
      updateSidebarState,
      sendMagicLink,
      sendWebhookWithNotification,
      nestedViewStack,
      onActionsClick,
      lockRecordAtTableLevel,
      updateTriggerAction,
      isInDetailOverviewHeader,
      sendToTopAction,
      sendToBottomAction,
      currentTablePage,
      detailTabViewFilterByTableSlug,
      handleDeleteFilesListFiles,
      inView,
      isTable,
      queryClient,
      updateRecordByCompositeKeyAsync,
      onActionSuccessCallback,
      record,
      handleViewAction,
      handleSidebarAction,
      prefillValueForEmailAction,
      isRecord,
      router,
      handleOpenRecordInCollapsibleSidebar,
      currentRecordId,
      currentRecordTableName,
      updateRecordAsync
    ]
  );

  useEffect(() => {
    if (!finalRecordId || finalRecordId !== recordData?.id || !currentAction?.id) return;
    if (currentAction.name === UI_ACTIONS.DUPLICATE && record?.id && tableName) {
      // Requires fetching all direct fields for record first
      const tableProps = schemaInstance?.extendedSchema?.[tableName];
      const directFields = [...(tableProps?.attributeIds || [])];
      if (directFields?.length) {
        const finalInput: RecordItem = {};
        directFields.forEach((field) => {
          if (!["id", "created_by", "created_in", "created_in_path"].includes(field)) {
            finalInput[field] = recordData[field];
          }
        });
        handleAddRecord(finalInput);
      }
    }
  }, [recordData, currentAction, finalRecordId, record, tableName, schemaInstance, handleAddRecord]);

  return { handleActionClick };
};

export default useActionsHandler;
