import { usePathname } from "next/navigation";
import { useCallback, useMemo } from "react";
import { getActiveViewFromPathName, getPathSegments, isInPathname } from "utils/server";

const useInLayout = () => {
  const pathname = usePathname();

  const pathSegments = useMemo(() => getPathSegments(pathname), [pathname]);

  const {
    isAdminPage,
    isRecord,
    isReportsPage,
    isStaticPage,
    isTable,
    isTilesPage,
    finalSlug,
    isAdminCellsPage,
    isAdminColumnTemplatesPage,
    isAdminMenuPage,
    isAdminSearchTable,
    isAdminTablesPage,
    isAdminTilesPage,
    showTableHeader,
    isAdminViewsPage,
    isAdminTilesEditPage,
  } = isInPathname(pathname);

  const getActiveView = useCallback(() => getActiveViewFromPathName(pathname), [pathname]);

  return {
    isAdminPage,
    isTable,
    isReportsPage,
    isStaticPage,
    isTilesPage,
    isRecord,
    finalSlug,
    isAdminMenuPage,
    isAdminSearchTable,
    isAdminCellsPage,
    isAdminTablesPage,
    isAdminColumnTemplatesPage,
    isAdminTilesPage,
    getActiveView,
    showTableHeader,
    pathSegments,
    isAdminViewsPage,
    isAdminTilesEditPage,
  };
};

export default useInLayout;
