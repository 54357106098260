"use client";

import MUITooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const Tooltip = styled(({ className, arrow = true, ...props }: TooltipProps) => (
  <MUITooltip data-testid="Tooltip" {...props} classes={{ popper: className }} arrow={arrow} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0A0D14",
    fontSize: 14
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#0A0D14"
  }
}));

export default Tooltip;
