"use client";

import { useEffect, useState } from "react";

import { appendRecordTypeLookupToCol } from "utils/columnUtils";
import { CellType, ViewOption } from "utils/constants";
import { getColumnHeaderTypeForRecordKey, getRecordForViewType } from "utils/dataUtils";

import { Page, TableColumnType } from "types/baTypes";
import { RecordItem } from "types/common";

import useRecordTypes from "./useRecordTypes";
import useSchemaState from "./useSchemaState";

const useFinalRecordHeaderMap = (pageData?: Page, recordData?: RecordItem, view = ViewOption.DETAIL_MAIN) => {
  const { schemaInstance } = useSchemaState();
  const { data: recordTypesData } = useRecordTypes();
  const [finalRecord, setFinalRecord] = useState<RecordItem | undefined>();
  const [colHeaderTypeMap, setColHeaderTypeMap] = useState<
    Record<string, { type: CellType; header: string; column: TableColumnType; columnRules?: RecordItem }>
  >({});

  useEffect(() => {
    if (!recordData?.id || !pageData?.table_name || !recordTypesData?.length || !schemaInstance?.extendedSchema) {
      return;
    }
    const { columns = [], views } = pageData;
    const formViewConfig = views?.find((view) => view.viewType === ViewOption.FORM && view.isDefault);
    const filteredColumns = columns
      .filter((col) => !!col.views?.[view]?.id || col.id === "26c7df9a-aa28-48e3-944c-d24f977994d8")
      .map((col) =>
        appendRecordTypeLookupToCol(col, recordTypesData || [], pageData?.table_name, schemaInstance?.extendedSchema)
      )
      .filter(Boolean) as TableColumnType[];
    filteredColumns.sort((a, b) =>
      !!a.sortOrder?.[view] && b.sortOrder?.[view] && (a.sortOrder?.[view] || 0) < (b.sortOrder?.[view] || 0) ? -1 : 1
    );
    const updatedRecord = getRecordForViewType({
      record: recordData,
      viewType: view,
      columnOptions: columns,
      finalColumns: filteredColumns,
      extendedSchema: schemaInstance?.extendedSchema,
      recordTypesData
    });

    const updatedHeaderMap = getColumnHeaderTypeForRecordKey(filteredColumns, formViewConfig);
    setFinalRecord(updatedRecord);
    setColHeaderTypeMap(updatedHeaderMap);
  }, [recordData, pageData, schemaInstance?.extendedSchema, view, recordTypesData]);

  return { finalRecord, colHeaderTypeMap };
};

export default useFinalRecordHeaderMap;
