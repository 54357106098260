import React, { Fragment } from "react";
import Link from "next/link";
import { Menu } from "@headlessui/react";
import DropdownLabel from "./DropdownLabel";

type DropdownMenuItemProps = {
  disabled?: boolean;
  link?: string;
  wrapper?: React.ReactElement;
};

const DropdownMenuItem = ({ disabled = false, wrapper, link, ...rest }: DropdownMenuItemProps) => {
  const label = <DropdownLabel as={wrapper} disabled={disabled} {...rest} />;
  return (
    <Menu.Item as={Fragment} disabled={disabled}>
      {link ? (
        <Link href={link} target="_blank">
          {label}
        </Link>
      ) : (
        label
      )}
    </Menu.Item>
  );
};

export default DropdownMenuItem;
