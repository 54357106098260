import React, { useCallback } from "react";
import clsx from "clsx";

import { LucideIcon } from "lucide-react";
import Skeleton from "components/Skeleton";
import Image from "components/Image";
import Tooltip from "components/Tooltip";
import { AvatarSize } from "utils/constants";
import { PeopleType, PeopleTypeAvailableForIcon } from "utils/constants";

export type AvatarProps = {
  size: AvatarSize;
  src?: string;
  initials?: string;
  alt?: string;
  title?: string;
  hasTooltip?: boolean;
  icon?: LucideIcon;
  mRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  classNameIcon?: string;
  classNameIconWrapper?: string;
  role?: "staff" | "client";
  isLoading?: boolean;
  type?: PeopleType;
  disabled?: boolean;
};

const Avatar = ({
  size = AvatarSize.MD,
  initials = "",
  src = "",
  alt = "",
  title = "",
  icon,
  mRef,
  className,
  classNameIcon,
  classNameIconWrapper,
  hasTooltip = false,
  isLoading = false,
  role = "staff",
  type,
  disabled = false,
}: AvatarProps) => {
  const sizeClass = {
    [AvatarSize.XS]: "h-[22px] w-[22px] min-w-[1.5rem]",
    [AvatarSize.SM]: "h-8 w-8 min-w-[2rem]",
    [AvatarSize.MD]: "h-9 w-9 min-w-[2.5rem]",
    [AvatarSize.LG]: "h-12 w-12 min-w-[3rem]",
    [AvatarSize.XL]: "h-16 w-16 min-w-[4rem]",
  };
  // This is created separately so global searches work, instead of text-${type}
  const textClass = {
    [AvatarSize.XS]: "text-xs",
    [AvatarSize.SM]: "text-sm",
    [AvatarSize.MD]: "text-md",
    [AvatarSize.LG]: "text-lg",
    [AvatarSize.XL]: "text-xl",
  };

  const imageSize = {
    [AvatarSize.XS]: {
      width: 20,
      height: 20,
    },
    [AvatarSize.SM]: {
      width: 32,
      height: 32,
    },
    [AvatarSize.MD]: {
      width: 40,
      height: 40,
    },
    [AvatarSize.LG]: {
      width: 48,
      height: 48,
    },
    [AvatarSize.XL]: {
      width: 64,
      height: 64,
    },
  };

  const roleClass = {
    client: "bg-primary-700",
    staff: "bg-purple-700",
  };

  const PeopleTypeColor = {
    [PeopleType.Pro]: "bg-primary-800",
    [PeopleType.Staff]: "bg-red-800",
    [PeopleType.Vendor]: "bg-yellow-700",
    [PeopleType.Client]: "bg-green-800",
    [PeopleType.Lead]: "bg-secondary-800",
  };

  const renderIcon = useCallback(() => {
    if (!icon) return null;

    const IconComponent = icon as LucideIcon;

    const iconClass = {
      [AvatarSize.XS]: "w-[9px] h-[9px]",
      [AvatarSize.SM]: "w-[9px] h-[9px]",
      [AvatarSize.MD]: "w-3 h-3",
      [AvatarSize.LG]: "w-3 h-3",
      [AvatarSize.XL]: "w-[15px] h-[15px]",
    };

    const iconContainerClass = {
      [AvatarSize.XS]: "w-3 h-3",
      [AvatarSize.SM]: "w-3 h-3",
      [AvatarSize.MD]: "w-4 h-4",
      [AvatarSize.LG]: "w-4 h-4",
      [AvatarSize.XL]: "w-5 h-5",
    };

    return (
      <div
        className={clsx(
          "absolute bottom-0 right-0 flex items-center justify-center rounded-full bg-base-primary",
          iconContainerClass[size],
          classNameIconWrapper
        )}
      >
        <IconComponent className={clsx("text-neutral-0", iconClass[size], classNameIcon)} />
      </div>
    );
  }, [icon, classNameIcon, classNameIconWrapper, size]);

  const Wrapper = ({ children }: { children: React.ReactElement }) => {
    if (hasTooltip && (title || alt)) {
      return <Tooltip title={title || alt}>{children}</Tooltip>;
    }

    return <>{children}</>;
  };

  const renderType = useCallback((type: PeopleType) => {
    if (!PeopleTypeAvailableForIcon.includes(type)) {
      return null;
    }

    return (
      <div
        className={clsx(
          "absolute -bottom-1 -right-1 flex h-[14px] w-[14px] flex-shrink-0 items-center justify-center rounded-full border border-neutral-0 text-3xs font-extrabold text-white",
          PeopleTypeColor[type]
        )}
      >
        {type[0]}
      </div>
    );
  }, []);

  if (isLoading) {
    return <Skeleton circle={true} className={clsx("!w-auto", sizeClass[size])} />;
  }

  if (!src) {
    return (
      <Wrapper>
        <span
          data-testid="Avatar"
          className={clsx(
            "relative inline-flex shrink-0 items-center rounded-full !text-baseinverse-primary",
            sizeClass[size],
            roleClass[role] ?? "bg-purple-700",
            className
          )}
        >
          <span
            className={clsx(
              "w-full text-center font-medium uppercase leading-none !text-baseinverse-primary",
              textClass[size]
            )}
          >
            {initials}
          </span>
          {disabled && (
            <div className="absolute inset-0 rounded-full bg-base-disabled bg-opacity-75 dark:bg-base-dark-disabled" />
          )}
          {type ? renderType(type) : icon ? renderIcon() : null}
        </span>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="relative" ref={mRef}>
        <Image
          data-testid="Avatar"
          className={clsx("inline-block shrink-0 rounded-full", sizeClass[size], className)}
          src={src}
          alt={alt}
          {...imageSize[size]}
        />
        {disabled && (
          <div className="absolute inset-0 rounded-full bg-base-disabled bg-opacity-75 dark:bg-base-dark-disabled" />
        )}
        {type ? renderType(type) : icon ? renderIcon() : null}
      </div>
    </Wrapper>
  );
};

Avatar.displayName = "Avatar";

export default React.memo(Avatar);
