import React, { Fragment, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { X as CloseIcon, LucideIcon } from "lucide-react";
import { ButtonAppearance, ButtonSize, ModalSize } from "utils/constants";
import IconButton from "components/IconButton";
import Button from "components/Button";
import { IconButtonSize } from "components/IconButton/utils";

export type ModalProps = {
  isOpen: boolean;
  title?: string;
  size?: ModalSize;
  fullScreen?: boolean;
  responsive?: boolean;
  children: React.ReactNode;
  mRef?: React.RefObject<HTMLDivElement> | null;
  closeIcon?: LucideIcon;
  closeLabel?: string;
  onClose: () => void;
  leftHeader?: React.ReactNode;
  leftHeaderClassName?: string;
  className?: string;
  classNameBg?: string;
  titleClassName?: string;
  closeOnOutsideClick?: boolean;
  showClose?: boolean;
  customCloseButtonIcon?: React.FunctionComponent<{ className: string }> | null;
  fullHeight?: boolean;
  hideOverlay?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  closeIconWrapperClassName?: string;
};

const Modal = ({
  isOpen = false,
  title,
  size = ModalSize.XL,
  fullScreen,
  responsive = false,
  children,
  mRef = null,
  closeIcon = CloseIcon,
  closeLabel = "Cancel",
  onClose,
  leftHeader,
  leftHeaderClassName = "",
  className,
  classNameBg,
  titleClassName,
  closeOnOutsideClick = true,
  showClose = true,
  customCloseButtonIcon,
  fullHeight = false,
  hideOverlay = false,
  closeIconWrapperClassName,
  ...rest
}: ModalProps) => {
  const sizeClass = {
    [ModalSize.XS]: "max-w-xs",
    [ModalSize.SM]: "max-w-sm",
    [ModalSize.MD]: "max-w-md",
    [ModalSize.LG]: "max-w-lg",
    [ModalSize.XL]: "max-w-[39rem]",
    [ModalSize.XXL]: "max-w-[70rem]"
  };
  const widthClass = sizeClass[size];

  const onDialogClose = useCallback(() => {
    if (closeOnOutsideClick) {
      onClose();
    }
  }, [closeOnOutsideClick, onClose]);

  const handleKeyClose = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      e.stopPropagation();

      if (isOpen) {
        onClose?.();
      }
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        data-testid="Modal"
        className={clsx("fixed inset-0 z-50 overflow-y-auto", className)}
        onClose={onDialogClose}
        onKeyDown={handleKeyClose}
        tabIndex={0}
        {...rest}
      >
        <div className="right-0 min-h-screen text-center" ref={mRef}>
          {!fullScreen && !hideOverlay && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/75" />
            </Transition.Child>
          )}

          {/* This element is to trick the browser into centering the modal contents. */}
          {!fullScreen && (
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
          )}

          {fullScreen && showClose && (
            <div className={clsx("fixed left-2 z-[31] lg:left-8", title ? "top-3" : "top-6")}>
              {customCloseButtonIcon ? (
                <IconButton onClick={onClose} icon={customCloseButtonIcon} size={ButtonSize.MD} className="flex" />
              ) : (
                <>
                  <Button
                    appearance={ButtonAppearance.SECONDARY}
                    size={ButtonSize.SM}
                    icon={closeIcon}
                    label={closeLabel}
                    onClick={onClose}
                    className="hidden lg:flex"
                  />
                  <IconButton onClick={onClose} icon={closeIcon} size={ButtonSize.MD} className="flex lg:hidden" />
                </>
              )}
            </div>
          )}

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-100"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-100"
          >
            <div
              className={clsx(
                "border-separator bg-dropdown dark:text-primary inline-block w-full overflow-hidden overflow-y-auto border text-left  align-middle",
                !fullScreen && "pop transform transition-all",
                fullScreen && "h-screen w-screen",
                responsive && `h-screen lg:my-8 lg:rounded-2xl ${widthClass} lg:max-h-[calc(100vh-9rem)`,
                !fullScreen && !responsive && !fullHeight && `my-8 rounded-2xl ${widthClass} max-h-[calc(100vh-9rem)]`,
                classNameBg ? classNameBg : "",
                fullHeight && "m-0 h-screen"
              )}
            >
              {!fullScreen && showClose && !title && (
                <div className={clsx("sticky top-5 flex w-full justify-end px-5", closeIconWrapperClassName)}>
                  <IconButton onClick={onClose} icon={closeIcon} size={IconButtonSize.SM} />
                </div>
              )}
              {title && (
                <div className="border-separator bg-dropdown sticky top-0 z-30 h-16 border-b py-5 text-center ">
                  <div className="absolute left-5 top-5">
                    {!fullScreen && showClose && (
                      <IconButton size={IconButtonSize.SM} icon={closeIcon} onClick={onClose} />
                    )}
                  </div>
                  <div
                    className={clsx(
                      "line-clamp-1 pl-12 pr-32 font-medium text-base-primary dark:text-neutral-300 lg:px-40",
                      titleClassName
                    )}
                  >
                    {title}
                  </div>
                  <div className={clsx("absolute right-8 top-3", leftHeaderClassName)}>{leftHeader}</div>
                </div>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
