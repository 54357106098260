"use client";
import React, { useEffect, useMemo, useState } from "react";
import NextImage, { ImageProps as NextImageProps } from "next/image";
import { FALLBACK_IMAGE_SRC } from "utils/constants";
import { buildSupabasePublicUrl } from "./utils";

export type ImageProps = NextImageProps & {
  src: string;
  alt: string;
  mRef?: React.Ref<HTMLImageElement>;
  onLoadingComplete?: (result: HTMLImageElement) => void;
};

const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

const triplet = (e1: number, e2: number, e3: number) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63);

const rgbDataURL = (r: number, g: number, b: number) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

const blurDataURL = rgbDataURL(153, 153, 153);

const ImageComponent = ({ src = "", alt = "", mRef, onLoadingComplete, ...rest }: ImageProps) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [src]);

  const isExternal = src.startsWith("http") || src.startsWith("blob");
  const finalSrc = useMemo(() => {
    const isExternal = src.startsWith("http") || src.startsWith("blob");
    return error ? buildSupabasePublicUrl(FALLBACK_IMAGE_SRC) : isExternal ? src : buildSupabasePublicUrl(src);
  }, [src, error]);

  return (
    <>
      {isExternal ? (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          ref={mRef}
          src={finalSrc}
          blurDataURL={blurDataURL}
          placeholder="blur"
          alt={alt}
          quality={80}
          onError={() => {
            setError(true);
          }}
          {...rest}
        />
      ) : (
        <NextImage
          ref={mRef}
          src={finalSrc}
          blurDataURL={blurDataURL}
          placeholder="blur"
          alt={alt}
          quality={80}
          onLoadingComplete={(result) => {
            if (result.naturalWidth === 0) {
              // broken image
              setError(true);
            }
            onLoadingComplete?.(result);
          }}
          onError={() => {
            setError(true);
          }}
          {...rest}
        />
      )}
    </>
  );
};

ImageComponent.displayName = "Image";

export default ImageComponent;
