"use client";

import React, { createContext, useCallback, useState } from "react";
import { NotificationRecord, NOTIFICATION_TYPE } from "components/Notifications/utils";

export interface NotificationsState {
  notificationsList: Array<NotificationRecord>;
  isNotificationPending: (notificationId: string) => boolean;
  addPendingNotification: (notificationState: NotificationRecord, forceMarkUnread?: boolean) => void;
  removePendingNotification: (notificationId: string) => void;
  setNotificationAsRead: (notificationId: string) => void;
  updateNotification: (notificationId: string, data: Partial<NotificationRecord>) => void;
  notificationToastRefs: Array<React.RefObject<HTMLDivElement>>; // Stores refs to toast notifications that will receive async confirmation from Supabase
  addNotificationToastRef: (ref: React.RefObject<HTMLDivElement>) => void;
}

export const NotificationContext = createContext<NotificationsState | null>(null);

const { Provider } = NotificationContext;

export const NotificationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [notificationsList, setNotificationsList] = useState<Array<NotificationRecord>>([]);
  const [notificationToastRefs, setNotificationToastRefs] = useState<Array<React.RefObject<HTMLDivElement>>>([]);

  const addPendingNotification = useCallback((notificationState: NotificationRecord, forceMarkUnread = true) => {
    setNotificationsList((prev) => {
      const existingNotificationIdx = prev.findIndex((notification) =>
        notification.type === NOTIFICATION_TYPE.ACTION
          ? notification.id === notificationState.action_props?.action_props?.id
          : notification.id === notificationState.id
      );

      // if notification not already in the list
      if (existingNotificationIdx === -1) {
        return [...prev, { ...notificationState, read: forceMarkUnread ? false : !!notificationState.read }];
      } else {
        // update the data for the notification.
        const newVal = [...prev];
        newVal[existingNotificationIdx] = {
          ...newVal[existingNotificationIdx],
          ...notificationState,
          read: forceMarkUnread ? false : !!notificationState.read,
        };
        return newVal;
      }
    });
  }, []);

  const removePendingNotification = useCallback((notificationId: string) => {
    setNotificationsList((prev) => prev.filter((n) => n.id !== notificationId));
  }, []);

  const setNotificationAsRead = useCallback(
    (notificationId: string) => {
      const readNotification = notificationsList.find((notifcation) => notifcation.id === notificationId);
      if (readNotification && !readNotification.read) {
        if (readNotification.onSuccessCallback) {
          readNotification.onSuccessCallback();
        }
        setNotificationsList((prev) =>
          prev.map((n) => {
            if (n.id === notificationId) {
              return { ...n, read: true };
            }
            return n;
          })
        );
      }
    },
    [notificationsList]
  );

  const isNotificationPending = useCallback(
    (notificationId: string) => {
      return notificationsList.some((n) => n.id === notificationId && !n.read);
    },
    [notificationsList]
  );

  const updateNotification = useCallback((notificationId: string, data: Partial<NotificationRecord>) => {
    setNotificationsList((prev) => prev.map((item) => (item.id === notificationId ? { ...item, ...data } : item)));
  }, []);

  const addNotificationToastRef = useCallback((ref: React.RefObject<HTMLDivElement>) => {
    setNotificationToastRefs((prev) => [...prev, ref]);
  }, []);

  return (
    <Provider
      value={{
        isNotificationPending,
        addPendingNotification,
        removePendingNotification,
        notificationsList,
        setNotificationAsRead,
        updateNotification,
        notificationToastRefs,
        addNotificationToastRef,
      }}
    >
      {children}
    </Provider>
  );
};
