import React, { useRef, useState } from "react";
import noop from "lodash/noop";
import { ChevronRightIcon, ExternalLinkIcon, LucideIcon } from "lucide-react";

import clsx from "clsx";
import { useHover } from "usehooks-ts";
import { ActionType, AvatarSize, PeopleType } from "utils/constants";

import Avatar from "../Avatar";

type DropdownLabelProps = {
  label?: string;
  description?: string;
  as?: React.ReactElement;
  className?: string;
  avatar?: string;
  initials?: string;
  icon?: LucideIcon | ((props: any) => JSX.Element) | null;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  items?: any[];
  action?: {
    type?: string;
    requireConfirmation?: boolean;
  };
  type?: PeopleType;
  linkToOpenInNewTab?: string;
  onExternalLinkClick?: () => void;
  isActive?: boolean;
  shortcutKey?: string;
  style?: React.CSSProperties;
};

const DropdownLabel = React.forwardRef(
  (
    {
      as = <span />,
      label,
      description,
      className = "",
      icon,
      avatar,
      initials,
      disabled = false,
      onClick = noop,
      items,
      action,
      type,
      linkToOpenInNewTab,
      onExternalLinkClick,
      isActive = false,
      shortcutKey,
      style
    }: DropdownLabelProps,
    ref
  ) => {
    const IconComponent = icon as LucideIcon;
    const [showConfirmation, setShowConfirmation] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const externalLinkRef = useRef<HTMLDivElement>(null);
    const isHovered = useHover(containerRef);

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const clickWasInExternalLInk = externalLinkRef.current?.contains(e.target as Node);

      if (clickWasInExternalLInk) return;

      if (showConfirmation || !action || !action?.requireConfirmation) {
        onClick(e);
      } else {
        setShowConfirmation(true);
      }
    };

    return (
      <div ref={containerRef}>
        {React.cloneElement(
          as,
          {
            ref,
            onMouseDown: handleClick,
            onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation(),
            className: clsx(
              "flex items-center justify-between rounded-md px-2.5 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700",
              isActive && "bg-gray-100",
              disabled ? "cursor-default" : "cursor-pointer",
              showConfirmation && action?.type === ActionType.POSITIVE && "!bg-green-700 dark:!bg-green-dark-700",
              showConfirmation && action?.type === ActionType.DANGER && "!bg-red-700 dark:!bg-red-dark-700",
              className
            ),
            role: "menuitem",
            style
          },
          <>
            <div className="flex w-full items-center justify-between space-x-3">
              {(avatar || initials) && <Avatar size={AvatarSize.SM} src={avatar} initials={initials} type={type} />}
              {icon && (
                <IconComponent
                  className={clsx(
                    "h-4 w-4",
                    disabled ? "text-neutral-300" : "t text-[#6b6f76] dark:text-[#f2f2f2] ",
                    showConfirmation && "!text-[#ffffff]"
                  )}
                />
              )}
              {label && (
                <div
                  className={`flex flex-1 flex-col ${
                    disabled ? "text-neutral-300  dark:text-neutral-0" : "text-primary"
                  }, `}
                >
                  <span className={clsx("text-primary text-sm font-medium", showConfirmation ? "!text-neutral-0" : "")}>
                    {showConfirmation ? `${label}? Are you sure?` : label}
                  </span>
                  <span className="text-2xs ">{description}</span>
                </div>
              )}
              {!!(linkToOpenInNewTab || onExternalLinkClick) && isHovered && (
                <div ref={externalLinkRef}>
                  <ExternalLinkIcon
                    className="h-4 w-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (linkToOpenInNewTab) {
                        window.open(linkToOpenInNewTab, "_blank");
                      } else {
                        onExternalLinkClick?.();
                      }
                    }}
                  />
                </div>
              )}
              {shortcutKey && (
                <div className="text-xs font-bold text-gray-400 dark:text-gray-600/60">{shortcutKey}</div>
              )}
            </div>

            {items && <ChevronRightIcon className={`ml-auto ${disabled ? "fill-neutral-300" : "fill-base-primary"}`} />}
          </>
        )}
      </div>
    );
  }
);
DropdownLabel.displayName = "DropdownLabel";

export default React.memo(DropdownLabel);
