import { useCallback } from "react";

import { RecordItem } from "types/common";
import useUpdateRecord from "./useUpdateRecord";

const useLockRecord = () => {
  const { updateRecordAsync } = useUpdateRecord();

  const lockRecordAtTableLevel = useCallback(
    async (record: RecordItem, tableName: string) => {
      if (!record?.id) return;

      const input: any = {
        id: record?.id,
        is_locked: !record?.is_locked,
      };
      await updateRecordAsync({
        tableName: tableName,
        input,
      });
    },
    [updateRecordAsync]
  );

  return {
    lockRecordAtTableLevel,
  };
};

export default useLockRecord;
