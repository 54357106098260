import { useContext } from "react";
import { NotificationContext, NotificationsState } from "context/NotificationsContext";

const useNotificationsState = () => {
  const notificationsState = useContext<NotificationsState | null>(NotificationContext);

  if (typeof notificationsState === "undefined" || typeof notificationsState !== "object") {
    return {
      notificationsList: [],
      isNotificationPending: () => false,
      addPendingNotification: () => {},
      removePendingNotification: () => {},
      setNotificationAsRead: () => {},
      notificationsPending: [],
      updateNotification: () => {},
      notificationToastRefs: [],
      addNotificationToastRef: () => {},
    };
  }

  return notificationsState as NotificationsState;
};

export type AdminContextType = ReturnType<typeof useNotificationsState>;

export default useNotificationsState;
