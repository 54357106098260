export const ADMIN_TABLES_PAGE_SLUG = "admin-tables";
export const ADMIN_MENUS_PAGE_SLUG = "admin-menus";
export const ADMIN_TILES_PAGE_SLUG = "admin-tiles";
export const ADMIN_CELLS_PAGE_SLUG = "admin-cells";
export const ADMIN_SEARCH_TABLE_PAGE_SLUG = "admin-searchtables";
export const ADMIN_COL_TEMPLATES = "admin-table-column-templates";
export const ADMIN_CUSTOM_VIEWS = "admin-views";

export const ADMIN_PAGE_SLUG_TO_PATH_MAP: Record<string, string> = {
  [`/${ADMIN_TABLES_PAGE_SLUG}`]: "tables",
  [`/${ADMIN_MENUS_PAGE_SLUG}`]: "menus",
  [`/${ADMIN_TILES_PAGE_SLUG}`]: "tiles",
  [`/${ADMIN_CELLS_PAGE_SLUG}`]: "cells",
  [`/${ADMIN_SEARCH_TABLE_PAGE_SLUG}`]: "searchTables",
  [`/${ADMIN_COL_TEMPLATES}`]: "column-templates",
  [`/${ADMIN_CUSTOM_VIEWS}`]: "views",
};
