import { useMutation } from "@tanstack/react-query";
import { PostgrestError } from "@supabase/supabase-js";

import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { upsertRecordToTable } from "lib/supabaseApi";
import type { UpsertInput, UpsertResponse } from "types/apiTypes";
import { ERROR_TYPES } from "utils/constants";
import useErrorLogger from "./useErrorLogger";
import useCurrentUser from "./useCurrentUser";

const useUpsertRecord = () => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();
  const { logError } = useErrorLogger();
  const { mutateAsync, isPending } = useMutation<UpsertResponse, unknown, UpsertInput>({
    mutationFn: ({ tableName, input }: UpsertInput) =>
      upsertRecordToTable(
        {
          tableName,
          input: Array.isArray(input)
            ? input.map((entry) =>
                currentUser?.is_super_admin && !entry.id
                  ? {
                      ...entry,
                      org_id: currentUser?.org_id
                    }
                  : entry
              )
            : currentUser?.is_super_admin && !input.id
              ? { ...input, org_id: currentUser?.org_id }
              : input
        },
        supabaseClient
      ),
    onError: (error, variables) => {
      logError({
        error: error as PostgrestError,
        source: "useUpsertRecord",
        type: ERROR_TYPES.HOOKS,
        message: (error as PostgrestError).message || "Error upserting records",
        url: window.location.href,
        additionalInfo: {
          variables
        }
      });
    }
  });

  return {
    upsertRecordAsync: mutateAsync,
    isLoading: isPending
  };
};

export default useUpsertRecord;
