import React, { Fragment, useRef, useState, useEffect } from "react";
import clsx from "clsx";
import { Menu, Transition } from "@headlessui/react";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import { LucideIcon, ChevronDown, ChevronUp } from "lucide-react";
import { TooltipProps } from "@mui/material/Tooltip";

import Tooltip from "components/Tooltip";

import { DropdownPosition } from "utils/constants";
import { RecordItem } from "types/common";

import DropdownMenuItem from "./DropdownMenuItem";

export type DropDownItemProps = {
  id: number | string;
  label: string;
  wrapper?: React.ReactElement;
  onClick?: () => void;
  icon?: LucideIcon | ((props: any) => JSX.Element) | null;
  avatar?: string;
  initials?: string;
  hasDivider?: boolean;
  isActive?: boolean;
  isHidden?: boolean;
  childItems?: DropDownItemProps[];
} & RecordItem;

type DropdownProps = {
  children?: React.ReactNode;
  MenuButton: React.ReactElement;
  className?: string;
  classNameContainer?: string;
  classNamePortal?: string;
  position?: PopperPlacementType;
  isLink?: boolean;
  as?: React.ElementType;
  active?: boolean;
  open?: boolean;
  items?: DropDownItemProps[];
  offset?: [number | null | undefined, number | null | undefined];
  mRef?: React.RefObject<HTMLDivElement> | null;
  isHovered?: boolean;
  showOnlyInHovered?: boolean;
  hidePortal?: boolean;
  disablePopperPortal?: boolean;
  tooltipText?: string;
  tooltipProps?: TooltipProps;
};

export const Dropdown = ({
  MenuButton,
  mRef,
  children,
  className,
  classNameContainer,
  classNamePortal,
  as = "div",
  isLink,
  items,
  isHovered,
  showOnlyInHovered = false,
  hidePortal = false,
  disablePopperPortal = false,
  tooltipText,
  tooltipProps,
  ...rest
}: DropdownProps) => {
  const timeout: { current: NodeJS.Timeout | null } = useRef(null);

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [isShowing, setIsShowing] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState<RecordItem>();

  const onMouseEnter = () => {
    clearTimeout(timeout.current as NodeJS.Timeout);
    setIsShowing(true);
  };
  const onMouseLeave = () => {
    timeout.current = setTimeout(() => setIsShowing(false), 50);
  };

  const $items = (
    <div className={clsx("py-1")}>
      {items?.map((item, index) => (
        <Fragment key={`item_${item.id || index}`}>
          {!item.isHidden && !item.childItems?.length && (
            <div key={`base_${item.id ?? index}`}>
              <div className="px-1">
                <DropdownMenuItem {...item} key={`menuItem_${item.id ?? index}`} />
              </div>
              {item.hasDivider && <div className="border-separator my-1 w-full border-t" />}
            </div>
          )}
          {(item.childItems?.length || 0) > 0 && (
            <div key={`child_${item.id ?? index}`}>
              <div className="px-1">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className="flex items-center justify-between rounded-md px-2.5 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700"
                      onClick={(e) => {
                        e.preventDefault();
                        setSubMenuItems((prev) => ({
                          ...prev,
                          [item.id]: !subMenuItems?.[item.id]
                        }));
                      }}
                    >
                      <span className="text-primary text-sm font-medium">{item.label}</span>
                      {subMenuItems?.[item.id] ? (
                        <ChevronUp className="h-5 w-5" />
                      ) : (
                        <ChevronDown className="h-5 w-5" />
                      )}
                    </div>
                  )}
                </Menu.Item>
                {subMenuItems?.[item.id] && (
                  <>
                    {item.childItems?.map((childItem: DropDownItemProps, childIndex: number) => (
                      <div key={`${childItem.id ?? childIndex}`}>
                        <div className="px-2">
                          <DropdownMenuItem {...childItem} key={`menuItem_${childItem.id ?? childIndex}`} />
                        </div>
                        {childItem.hasDivider && <div className="border-separator my-1 w-full border-t" />}
                      </div>
                    ))}
                  </>
                )}
              </div>
              {item.hasDivider && <div className="border-separator my-1 w-full border-t" />}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );

  const renderItems = () => {
    return (
      <div className={clsx("my-1", classNamePortal)}>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave={!isLink ? "transition ease-in duration-75" : ""}
          leaveFrom={!isLink ? "transform opacity-100 scale-100" : ""}
          leaveTo={!isLink ? "transform opacity-0 scale-95" : ""}
          show={(isLink && isShowing) || undefined}
        >
          <Menu.Items
            className={clsx(
              "text-primary bg-dropdown origin-top-left  rounded-lg  shadow-lg focus:outline-none",
              items && items?.length > 0 && "border-separator min-w-[250px] rounded-lg border ",
              className
            )}
            onClick={() => isLink && setIsShowing(false)}
            static
          >
            {items && items?.length > 0 ? $items : children}
          </Menu.Items>
        </Transition>
      </div>
    );
  };

  useEffect(() => {
    if (!items?.length) {
      return;
    }
    const subMenuItemVals: RecordItem = {};
    items.forEach((item) => {
      if ((item.childItems?.length || 0) > 0) {
        subMenuItemVals[item.id] = false;
      }
    });
  }, [items]);

  return (
    <Menu
      as={as}
      data-testid="Dropdown"
      className={clsx("relative inline-block text-left", classNameContainer)}
      ref={mRef}
      {...rest}
    >
      {({ open }) => {
        const id = open || isShowing ? "popper" : undefined;

        return (
          <>
            <div
              onMouseEnter={() => isLink && onMouseEnter()}
              onMouseLeave={() => isLink && onMouseLeave()}
              className={showOnlyInHovered && !isHovered && !open ? "hidden" : undefined}
            >
              <div ref={setTargetElement}>
                {!!tooltipText ? (
                  <Tooltip title={tooltipText} {...tooltipProps}>
                    {isLink ? (
                      React.cloneElement(MenuButton, { open: open || isShowing })
                    ) : (
                      <Menu.Button as={Fragment}>
                        {React.cloneElement(MenuButton, { open: open || isShowing })}
                      </Menu.Button>
                    )}
                  </Tooltip>
                ) : (
                  <>
                    {isLink ? (
                      React.cloneElement(MenuButton, { open: open || isShowing })
                    ) : (
                      <Menu.Button as={Fragment}>
                        {React.cloneElement(MenuButton, { open: open || isShowing })}
                      </Menu.Button>
                    )}
                  </>
                )}
              </div>
              {hidePortal ? (
                <>{renderItems()}</>
              ) : (
                <Popper
                  open={open || isShowing}
                  id={id}
                  anchorEl={targetElement}
                  disablePortal={disablePopperPortal}
                  className="z-50"
                  placement="bottom-start"
                >
                  {renderItems()}
                </Popper>
              )}
            </div>
          </>
        );
      }}
    </Menu>
  );
};

Dropdown.POSITIONS = DropdownPosition;

export default Dropdown;
