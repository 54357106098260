import { Loader as SpinnerIcon } from "lucide-react";
import clsx from "clsx";

const Loader = ({ className, hidden = false }: { className?: string; hidden?: boolean }) => {
  if (hidden) {
    return null;
  }

  return (
    <div className="flex h-full w-full items-center justify-center">
      <SpinnerIcon
        role="status"
        className={clsx("spinner-border inline-block h-8 w-8 animate-spin rounded-full", className)}
      />
    </div>
  );
};
export default Loader;
