import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { getPageByPath } from "lib/adminApi";
import { QueryHookOptions } from "types/common";
import { USER_TYPE } from "utils/constants";

import useUserType from "./useUserType";
import useCurrentUser from "./useCurrentUser";

type usePageByPathProps = {
  slug: string;
  userTypeQP?: string;
  source?: string;
  isAdminPage?: boolean;
};

const usePageByPath = ({ slug, userTypeQP, source, isAdminPage }: usePageByPathProps, options?: QueryHookOptions) => {
  const supabaseClient = useSupabaseBrowser();
  const { userType } = useUserType();
  const currentUser = useCurrentUser();
  const finalUserType = currentUser?.type === USER_TYPE.STAFF && userTypeQP ? (userTypeQP as USER_TYPE) : userType;
  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ["page", slug, finalUserType, currentUser?.org_id, isAdminPage],
    queryFn: () => getPageByPath(supabaseClient, "/" + slug, finalUserType, source, currentUser?.org_id, isAdminPage),
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!slug,
    ...options
  });

  return {
    data: data?.data,
    isLoading: isLoading || !userType,
    isFetching,
    refetch
  };
};

export default usePageByPath;
