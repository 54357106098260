const getFirstCharacter = (text = "") => {
  const re = /[A-Za-z]/g;
  const matches = text.match(re);
  if (!matches) return "";

  return matches[0];
};

const generateInitials = (text = "") => {
  if (!text) return "";

  const firstWord = text.split(" ")[0];
  return firstWord.includes("@") ? getFirstCharacter(firstWord) : firstWord[0];
};

export default generateInitials;
