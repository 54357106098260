import set from "lodash/set";
import get from "lodash/get";
import { TableColumnType } from "types/baTypes";
import { RecordItem } from "types/common";
import { ViewOption } from "utils/constants";

export default class LexoRank {
  private value: string;
  private bucket: string;

  constructor(value: string, bucket = "0") {
    if (!value || !bucket) {
      throw new Error(`Value is required`);
    }

    if (!LexoRank.isValidLexValue(value)) {
      throw new Error(`Invalid lex value "${value}"`);
    }
    if (!LexoRank.isValidLexBucket(bucket)) {
      throw new Error(`Invalid lex bucket "${bucket}"`);
    }
    this.value = value;
    this.bucket = bucket;
  }

  static from(lex: LexoRank | string) {
    if (lex instanceof LexoRank) {
      return new LexoRank(lex.value, lex.bucket);
    }
    const { value, bucket } = this.parse(lex);
    return new LexoRank(value, bucket);
  }
  static parse(lex: string) {
    const regex = /^(?<bucket>[0-2])\|(?<value>[0-9a-z]*[1-9a-z])$/;
    const match = regex.exec(lex);
    if (!match) {
      throw new Error("Invalid lex string");
    }
    return { value: match.groups?.value || "", bucket: match.groups?.bucket || "" };
  }
  toString() {
    return `${this.bucket}|${this.value}`;
  }
  static nextBucket(bucket: string) {
    if (!this.isValidLexBucket(bucket)) {
      throw new Error(`Invalid lex bucket "${bucket}"`);
    }
    if (bucket === "2") return "0";
    return String.fromCharCode(bucket.charCodeAt(0) + 1);
  }
  static prevBucket(bucket: string) {
    if (!this.isValidLexBucket(bucket)) {
      throw new Error(`Invalid lex bucket "${bucket}"`);
    }
    if (bucket === "0") return "2";
    return String.fromCharCode(bucket.charCodeAt(0) - 1);
  }
  static isValidLexValue(value: string) {
    const regex = /^[0-9a-z]*[1-9a-z]$/;
    return regex.test(value);
  }
  static isValidLexBucket(bucket: string) {
    const regex = /^[0-2]$/;
    return regex.test(bucket);
  }
  lessThan(lex: LexoRank) {
    const other = LexoRank.from(lex);
    const len = Math.max(this.value.length, other.value.length);
    for (let idx = 0; idx < len; idx++) {
      const charA = this.value[idx];
      const charB = other.value[idx];
      if (!charB) return false; // a is more specific
      if (!charA) return true; // b is more specific
      if (charA < charB) return true;
      if (charA > charB) return false;
    }
    return false;
  }
  increment() {
    for (let idx = this.value.length - 1; idx >= 0; idx--) {
      const char = this.value[idx];
      if (char === "z") continue;
      const newVal = this.value.substring(0, idx) + LexoRank.incrementChar(char);
      return new LexoRank(newVal, this.bucket);
    }
    const newVal = this.value + "1";
    return new LexoRank(newVal, this.bucket);
  }
  decrement() {
    const length = this.value.length;
    const char = this.value[length - 1];
    if (char !== "1") {
      const newVal = this.value.substring(0, length - 1) + LexoRank.decrementChar(char);
      return new LexoRank(newVal, this.bucket);
    }
    if (this.hasNonZeroLeadingChars()) {
      const newVal = LexoRank.cleanTrailingZeros(this.value.substring(0, length - 1));
      return new LexoRank(newVal, this.bucket);
    }
    const newVal = "0" + this.value;
    return new LexoRank(newVal, this.bucket);
  }
  hasNonZeroLeadingChars() {
    return this.value.length > 1 && !this.value.substr(0, this.value.length - 1).match(/^0+$/);
  }
  static cleanTrailingZeros(str: string) {
    const regex = /^(?<value>[0-9a-z]*[1-9a-z])0*$/;
    const match = regex.exec(str);
    if (!match) {
      throw new Error("Invalid lex string");
    }
    return match.groups?.value || "";
  }
  append(str: string) {
    return new LexoRank(this.value + str, this.bucket);
  }
  static incrementChar(char: string) {
    if (char === "z") return "-1";
    if (char === "9") return "a";
    return String.fromCharCode(char.charCodeAt(0) + 1);
  }
  static decrementChar(char: string) {
    if (char === "1") return "-1";
    if (char === "a") return "9";
    return String.fromCharCode(char.charCodeAt(0) - 1);
  }
  static between(lexBefore?: LexoRank | string, lexAfter?: LexoRank | string) {
    if (!lexBefore && !lexAfter) {
      throw new Error("Only one argument may be null");
    }

    if (!lexAfter && lexBefore) {
      return LexoRank.from(lexBefore).increment();
    }
    if (!lexBefore && lexAfter) {
      return LexoRank.from(lexAfter).decrement();
    }
    if (lexBefore && lexAfter) {
      const before = LexoRank.from(lexBefore);
      const after = LexoRank.from(lexAfter);
      if (before.bucket !== after.bucket) {
        throw new Error("Lex buckets must be the same");
      }
      if (!before.lessThan(after)) {
        throw new Error(`${before.value} is not less than ${after.value}`);
      }
      const incremented = before.increment();
      if (incremented.lessThan(after)) return incremented;
      const plus1 = before.append("1");
      if (plus1.lessThan(after)) return plus1;
      let pre = "0";
      let plus01 = before.append(`${pre}1`);
      while (!plus01.lessThan(after)) {
        pre += "0";
        plus01 = before.append(`${pre}1`);
      }
      return plus01;
    }
  }
  static getNullRanksForTableColumn(items: TableColumnType[], viewType: ViewOption, sortField: string) {
    items.forEach((item, index) => {
      if (!get(item, sortField)) {
        // if first item
        if (index === 0) {
          const newRank = LexoRank.getNewRank([], 0, 0);
          set(item, sortField, newRank?.toString());
        } else {
          const prevItem = items[index - 1];
          const nextItem = items[index + 1];

          if (prevItem?.sortOrder?.[viewType]) {
            const prevRank = prevItem?.sortOrder?.[viewType]
              ? LexoRank.from(prevItem.sortOrder[viewType] as string)
              : undefined;
            const nextRank = nextItem?.sortOrder?.[viewType]
              ? LexoRank.from(nextItem?.sortOrder?.[viewType] as string)
              : undefined;
            const newRank = LexoRank.between(prevRank, nextRank);
            set(item, sortField, newRank?.toString());
          }
        }
      }
    });
    return items;
  }

  static getNullRanksForItems(items: RecordItem[], rankFieldName = "sort_order") {
    items.forEach((item, index) => {
      if (!item?.[rankFieldName]) {
        if (index - 1 < 0) {
          const newRank = LexoRank.getNewRank([], 0, 0);
          item[rankFieldName] = newRank?.toString();
        }
        const prevItem = items[index - 1];
        const nextItem = items[index + 1];

        if (prevItem?.[rankFieldName]) {
          const prevRank = prevItem?.[rankFieldName] ? LexoRank.from(prevItem[rankFieldName] as string) : undefined;
          const nextRank = nextItem?.[rankFieldName] ? LexoRank.from(nextItem[rankFieldName] as string) : undefined;
          const newRank = LexoRank.between(prevRank, nextRank);
          item[rankFieldName] = newRank?.toString();
        }
      }
    });
    return items;
  }

  static getNewRank(items: RecordItem[], oldPosition: number, newPosition: number, rankFieldName = "sort_order") {
    // if no items are there create a new rank;
    if (items.length === 0) {
      return new LexoRank("aa");
    }
    // items at start
    if (newPosition === 0) {
      const rank = LexoRank.from(get(items[0], rankFieldName));
      return rank.decrement();
    }
    // item at end
    if (newPosition === items.length - 1) {
      const rank = LexoRank.from(get(items[items.length - 1], rankFieldName));
      return rank.increment();
    }

    if (oldPosition < newPosition) {
      // item in between
      const rank1 = LexoRank.from(get(items[newPosition], rankFieldName));
      const rank2 = LexoRank.from(get(items[newPosition + 1], rankFieldName));
      return LexoRank.between(rank1, rank2);
    } else {
      const rank1 = LexoRank.from(get(items[newPosition - 1], rankFieldName));
      const rank2 = LexoRank.from(get(items[newPosition], rankFieldName));
      return LexoRank.between(rank1, rank2);
    }
  }
  static getNewRanks(
    items: RecordItem[],
    ranksCount: number,
    oldPosition: number,
    newPosition: number,
    rankFieldName = "sort_order",
    stringStartRank?: string
  ) {
    // if no items are there create a new rank;
    if (items.length === 0) {
      const newRanks = [];
      let startRank = stringStartRank ? LexoRank.from(stringStartRank).increment() : new LexoRank("aa");
      for (let i = 0; i < ranksCount; i++) {
        newRanks.push(startRank);
        startRank = startRank.increment();
      }
      return newRanks;
    }

    // items at start
    if (newPosition === 0) {
      const newRanks = [];
      let rank = LexoRank.from(`${items[0][rankFieldName]}`);
      rank = rank.decrement();
      for (let i = 0; i < ranksCount; i++) {
        newRanks.unshift(rank);
        rank = rank.decrement();
      }
      return newRanks;
    }

    // item at end
    if (newPosition === items.length - 1) {
      const newRanks = [];
      let rank = LexoRank.from(`${items[items.length - 1][rankFieldName]}`);
      rank = rank.increment();
      for (let i = 0; i < ranksCount; i++) {
        newRanks.push(rank);
        rank = rank.increment();
      }
      return newRanks;
    }

    if (oldPosition < newPosition) {
      const newRanks = [];
      // item in between
      let rank1 = LexoRank.from(`${items[newPosition][rankFieldName]}`);
      const rank2 = LexoRank.from(`${items[newPosition + 1][rankFieldName]}`);

      for (let i = 0; i < ranksCount; i++) {
        const rank = LexoRank.between(rank1, rank2);
        newRanks.push(rank);
        if (rank) {
          rank1 = rank;
        }
      }
      return newRanks;
    } else {
      const newRanks = [];
      const rank1 = LexoRank.from(`${items[newPosition - 1][rankFieldName]}`);
      let rank2 = LexoRank.from(`${items[newPosition][rankFieldName]}`);
      for (let i = 0; i < ranksCount; i++) {
        const rank = LexoRank.between(rank1, rank2);
        newRanks.unshift(rank);
        if (rank) {
          rank2 = rank;
        }
      }
      return newRanks;
    }
  }
}
