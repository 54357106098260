import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { PostgrestError } from "@supabase/supabase-js";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { getCompositeKeysFromCols } from "utils/dataUtils";
import { getTableDataById } from "lib/supabaseApi";
import { TableColumnType, TableFilterType } from "types/baTypes";
import { QueryHookOptions } from "types/common";
import { ERROR_TYPES } from "utils/constants";
import useSchemaState from "./useSchemaState";
import useSupabaseAltClient from "./useSupabaseAltClient";
import useErrorLogger from "./useErrorLogger";
import useCurrentUser from "./useCurrentUser";

type useTableDataByIdProps = {
  slug?: string;
  id: string;
  tableName: string | undefined;
  columns?: TableColumnType[];
  hasPublicId?: boolean;
  altSchema?: string;
  filters?: TableFilterType[];
  source?: string;
};

const useTableDataById = (
  { slug, id, tableName, columns, hasPublicId, altSchema, filters, source }: useTableDataByIdProps,
  options?: QueryHookOptions
) => {
  const { logError } = useErrorLogger();
  const supabaseClient = useSupabaseBrowser();
  const schemaClientMap = useSupabaseAltClient();
  const { schemaInstance } = useSchemaState();
  const currentUser = useCurrentUser();

  const compositeKeysByTable = getCompositeKeysFromCols(columns, schemaInstance?.extendedSchema);

  // When passing large objects like columns only pass the data needed, for example of lookup filters
  // are needed update the map to columns?.map((col) => {id: col.id, lookupFilters : col.lookupFilters })
  // Do not pass the entire column object or any large objects as this takes up memory and increases
  // the hashing and matching duration of the query key
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: [
      "record",
      (slug || "").replace("/", ""),
      id,
      columns?.map((col) => col.id),
      hasPublicId,
      currentUser?.org_id
    ],
    queryFn: () =>
      getTableDataById(
        {
          tableName: tableName || "",
          id,
          columns,
          public_id: hasPublicId ? id : undefined,
          filters,
          source: "useTableDataByID" + source,
          organizationId: currentUser?.org_id || ""
        },
        altSchema && schemaClientMap?.[altSchema] ? schemaClientMap[altSchema] : supabaseClient,
        compositeKeysByTable,
        schemaInstance?.extendedSchema
      ),
    enabled:
      !!tableName &&
      !!id &&
      ((columns?.length && !!compositeKeysByTable) || !columns?.length) &&
      !Object.keys(schemaInstance?.extendedSchema || {}).length,
    ...options
  });

  useEffect(() => {
    if (!data?.error) {
      return;
    }
    logError({
      error: data.error as PostgrestError,
      source: "useTableDataById",
      type: ERROR_TYPES.HOOKS,
      message: (data.error as PostgrestError).message || `Error fetching record id ${id} from table ${tableName}`,
      url: window.location.href,
      additionalInfo: {
        tableName,
        id
      }
    });
  }, [data?.error, tableName, id, source, logError]);

  return {
    data: data?.data?.[0],
    isLoading,
    refetch,
    error: data?.error || error
  };
};

export default useTableDataById;
