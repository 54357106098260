import { Notification } from "types/apiTypes";
import { RecordItem } from "types/common";

export enum NOTIFICATION_TYPE {
  ACTION = "action",
  NOTE_MENTION = "note_mention",
  INFO = "info",
  SYSTEM = "system",
  FORM_MODAL = "form_modal",
  STATIC_CUSTOM_FORM = "static_custom_form",
  QUIZ_SUBMISSION_COMPLETED = "quiz_submission_completed",
  NOTIFICATION_FORM = "notification_form"
}

export const VISIBLE_NOTIFICATION_TYPES = [NOTIFICATION_TYPE.NOTE_MENTION, NOTIFICATION_TYPE.NOTIFICATION_FORM];

export enum NOTIFICATION_ACTION {
  ACTION_SUCCESS = "action_success",
  RESET = "reset",
  TABLE_RESET = "table_reset",
  CONFETTI = "confetti",
  WRITE_ROLLUP = "write_rollup",
  ACTION_ERROR = "action_error",
  RECORD_RESET = "record_reset",
  APP_NEW_VERSION = "app_new_version"
}

export enum NOTIFICATION_READ_STATUS {
  PENDING = "pending",
  READ = "read"
}

export type NotificationRecord = {
  id: string;
  recordId?: string;
  action_props?: RecordItem | null;
  tableName?: string;
  action: string;
  userId?: string;
  read?: boolean;
  onSuccessCallback?: () => void;
  pageId?: string;
  message?: string;
  type: NOTIFICATION_TYPE;
  createdAt?: string;
  redirectUrl?: string;
};
export const transformPayloadToNotificationRecord = (payload: Notification): NotificationRecord => {
  const { id, action_props, tablename, action, for_user_id, record_id, read, message, created_at, type } = payload;
  // In some cases action_props includes an object with action_props key from Make
  const finalActionProps = action_props?.action_props ? action_props.action_props : action_props;
  return {
    id,
    action,
    recordId: record_id,
    action_props: finalActionProps,
    tableName: tablename,
    userId: for_user_id,
    read,
    type: type as NOTIFICATION_TYPE,
    message,
    createdAt: created_at,
    redirectUrl: payload.redirect_url
  };
};

export const transformNotificationRecordToNotificationType = (
  notification: Partial<NotificationRecord>
): Partial<Notification> => {
  return {
    id: notification.id,
    action: notification.action,
    record_id: notification.recordId,
    action_props: notification.action_props,
    tablename: notification.tableName,
    for_user_id: notification.userId,
    read: notification.read,
    message: notification.message,
    created_at: notification.createdAt,
    type: notification.type,
    redirect_url: notification.redirectUrl
  };
};

export const NOTIFICATION_TYPE_SKIP_BY_GLOBAL_HANDLER = [NOTIFICATION_TYPE.QUIZ_SUBMISSION_COMPLETED];
