"use client";

import { useCallback, useMemo } from "react";

import { ViewAction } from "types/baTypes";
import { RecordItem } from "types/common";

import { ActionType, PAGE_ACTION_VISIBILITY, UI_ACTIONS, ViewOption } from "utils/constants";
import useCurrentUser from "hooks/useCurrentUser";
import useActionsHandler from "hooks/useActionsHandler";

import CellAction from "./CellAction";

type ActionsHandlerProps = {
  actions?: ViewAction[];
  iconProps?: RecordItem;
  additionalProps?: RecordItem;
  record: RecordItem;
  tableName: string;
  displayMode?: React.ElementType; // Used to display actions in different ways
  className?: string;
  isInDetailHeader?: boolean;
  formattedRecord?: RecordItem;
  tablePath: string;
  onActionsClick?: (action: ViewAction, row: RecordItem) => void;
  hidePortal?: boolean;
  isAdmin?: boolean;
  isLocked?: boolean;
  isHovered?: boolean;
  showOnlyInHovered?: boolean;
  isNotificationActions?: boolean;
  disablePopperPortal?: boolean;
  isInDetailOverviewHeader?: boolean;
  showExposedActions?: boolean;
  inView?: ViewOption;
  onActionSuccessCallback?: (editedRow: RecordItem, actionName: UI_ACTIONS) => void;
  parentRecordSlug?: string;
  hideDropdownOnShowExposedActions?: boolean;
};

const ActionsHandler = ({
  actions,
  iconProps,
  additionalProps,
  record,
  tableName,
  className,
  formattedRecord,
  displayMode,
  tablePath,
  onActionsClick,
  hidePortal = false,
  isAdmin = false,
  isLocked = false,
  isHovered = false,
  showOnlyInHovered = false,
  disablePopperPortal = false,
  isInDetailOverviewHeader,
  showExposedActions = false,
  inView,
  onActionSuccessCallback,
  parentRecordSlug,
  hideDropdownOnShowExposedActions = false
}: ActionsHandlerProps) => {
  const currentUser = useCurrentUser();

  const { handleActionClick } = useActionsHandler({
    tableName,
    tablePath,
    onActionSuccessCallback,
    parentRecordSlug,
    inView,
    isInDetailOverviewHeader,
    record,
    onActionsClick
  });

  const finalActions = useMemo(() => {
    if (!actions?.length || actions.every((action) => action.isHidden)) {
      return undefined;
    }
    return actions
      .map((action) => {
        if (action.visibility !== PAGE_ACTION_VISIBILITY.BULK) {
          if (action.type === ActionType.LOCK && isLocked) {
            return {
              ...action,
              name: "Unlock Record",
              icon: "unlock"
            };
          }
          return action;
        }
      })
      .filter(Boolean);
  }, [actions, isLocked]);

  const handleActionClicked = useCallback(
    async (action: ViewAction, record: RecordItem) => {
      if (
        isAdmin &&
        [UI_ACTIONS.DELETE, UI_ACTIONS.DUPLICATE].includes(action.name as UI_ACTIONS) &&
        typeof onActionsClick === "function"
      ) {
        onActionsClick(action, record);
        return;
      }

      handleActionClick({
        action,
        formattedRecord
      });
    },
    [formattedRecord, isAdmin, onActionsClick, handleActionClick]
  );

  if (finalActions?.length === 0) return null;

  if (displayMode) {
    const DisplayMode = displayMode;

    return (
      <DisplayMode
        {...additionalProps}
        row={record}
        actions={finalActions}
        showTooltip
        onActionClick={handleActionClicked}
      />
    );
  }

  return (
    <CellAction
      row={record}
      actions={finalActions as ViewAction[]}
      iconProps={iconProps}
      onActionClick={handleActionClicked}
      className={className}
      hidePortal={hidePortal}
      disablePopperPortal={disablePopperPortal}
      isHovered={isHovered}
      showOnlyInHovered={showOnlyInHovered}
      isAdmin={currentUser?.is_admin}
      showExposedActions={showExposedActions}
      hideDropdownOnShowExposedActions={hideDropdownOnShowExposedActions}
      view={inView}
    />
  );
};

export default ActionsHandler;
