import { useCallback, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import capitalize from "lodash/capitalize";

import { RecordItem } from "types/common";
import LexoRank from "lib/LexoRank";
import toast, { ToastId } from "utils/toast";
import { TOAST_TYPE } from "utils/constants";
import useTableActionsState from "./useTableActionsState";
import useUpsertRecord from "./useUpsertRecord";
import useUpdateRecord from "./useUpdateRecord";

const useSendToAction = (tableSlug: string, tableName?: string) => {
  const queryClient = useQueryClient();
  const { sendToActionByTableSlug } = useTableActionsState();
  const { upsertRecordAsync } = useUpsertRecord();
  const { updateRecordAsync } = useUpdateRecord();
  const progressToastId = useRef<ToastId | null>(null);

  const sendToTopAction = useCallback(
    async (records: RecordItem[]) => {
      const sortToActionInfo = sendToActionByTableSlug?.[tableSlug];

      if (!sortToActionInfo || !sortToActionInfo?.sortColumnName || !sortToActionInfo?.firstSortRank || !tableName) {
        return;
      }

      const rowsIds = records?.map((row) => row.id);

      let firstSortLexoRank = LexoRank.from(sortToActionInfo.firstSortRank);
      const inputs = rowsIds.map((id) => {
        firstSortLexoRank = firstSortLexoRank.decrement();
        return {
          id,
          [sortToActionInfo.sortColumnName as string]: firstSortLexoRank.toString(),
        };
      });

      const label = records.length > 1 ? "cards" : "card";
      progressToastId.current = toast.success(`Sending ${label} to top...`);

      if (sortToActionInfo.isJoinTable) {
        const responses = await Promise.all(inputs.map((input) => updateRecordAsync({ tableName, input })));

        queryClient.invalidateQueries({ queryKey: ["table", tableName] });
        if (responses?.some((resp) => !!resp?.error)) {
          toast.update(progressToastId.current, `Some ${label} failed to sort!`, {
            type: TOAST_TYPE.ERROR,
          });
          return undefined;
        } else {
          toast.update(progressToastId.current, `${capitalize(label)} sent to top!`, {
            type: TOAST_TYPE.SUCCESS,
          });
        }
      } else {
        const response = await upsertRecordAsync({
          tableName,
          input: inputs,
        });
        if (response.error) {
          toast.update(progressToastId.current, `${capitalize(label)} update failed!`, {
            type: TOAST_TYPE.ERROR,
          });
        }

        queryClient.invalidateQueries({ queryKey: ["table", tableName] });
        toast.update(progressToastId.current, `${capitalize(label)} sent to top!`, {
          type: TOAST_TYPE.SUCCESS,
        });
      }
    },
    [sendToActionByTableSlug, tableSlug, tableName, updateRecordAsync, upsertRecordAsync, queryClient]
  );

  const sendToBottomAction = useCallback(
    async (records: RecordItem[]) => {
      const sortToActionInfo = sendToActionByTableSlug?.[tableSlug];

      if (!sortToActionInfo || !sortToActionInfo?.sortColumnName || !sortToActionInfo?.lastSortRank || !tableName) {
        return;
      }

      const rowsIds = records?.map((row) => row.id);

      let lastSortLexoRank = LexoRank.from(sortToActionInfo.lastSortRank);
      const inputs = rowsIds.map((id) => {
        lastSortLexoRank = lastSortLexoRank.increment();
        return {
          id,
          [sortToActionInfo.sortColumnName as string]: lastSortLexoRank.toString(),
        };
      });

      const label = records.length > 1 ? "cards" : "card";
      progressToastId.current = toast.success(`Sending ${label} to bottom...`);

      if (sortToActionInfo.isJoinTable) {
        const responses = await Promise.all(inputs.map((input) => updateRecordAsync({ tableName, input })));

        queryClient.invalidateQueries({ queryKey: ["table", tableName] });
        if (responses?.some((resp) => !!resp?.error)) {
          toast.update(progressToastId.current, `Some ${label} failed to sort!`, {
            type: TOAST_TYPE.ERROR,
          });
          return undefined;
        } else {
          toast.update(progressToastId.current, `${capitalize(label)} sent to bottom!`, {
            type: TOAST_TYPE.SUCCESS,
          });
        }
      } else {
        const response = await upsertRecordAsync({
          tableName,
          input: inputs,
        });
        if (response.error) {
          toast.update(progressToastId.current, `${capitalize(label)} update failed!`, {
            type: TOAST_TYPE.ERROR,
          });
        }

        queryClient.invalidateQueries({ queryKey: ["table", tableName] });
        toast.update(progressToastId.current, `${capitalize(label)} sent to bottom!`, {
          type: TOAST_TYPE.SUCCESS,
        });
      }
    },
    [sendToActionByTableSlug, tableSlug, tableName, updateRecordAsync, upsertRecordAsync, queryClient]
  );

  return {
    sendToTopAction,
    sendToBottomAction,
  };
};

export default useSendToAction;
