import clsx from "clsx";
import { MoreHorizontalIcon } from "lucide-react";
import { useMemo } from "react";

import Dropdown from "components/Dropdown";
import Icon, { IconProps } from "components/Icon";
import IconButton from "components/IconButton";
import { IconButtonColor } from "components/IconButton/utils";
import Tooltip from "components/Tooltip";
import { ViewAction } from "types/baTypes";
import { RecordItem } from "types/common";
import { ViewOption } from "utils/constants";

type CellActionProp = {
  actions?: ViewAction[];
  iconProps?: RecordItem;
  row: RecordItem;
  onActionClick: (action: ViewAction, row: RecordItem) => void;
  className?: string;
  hidePortal?: boolean;
  isHovered?: boolean;
  showOnlyInHovered?: boolean;
  isAdmin?: boolean;
  disablePopperPortal?: boolean;
  showExposedActions?: boolean;
  hideDropdownOnShowExposedActions?: boolean;
  view?: ViewOption;
};

const CellAction = ({
  actions = [],
  iconProps,
  row,
  onActionClick,
  className,
  hidePortal = false,
  isHovered = false,
  showOnlyInHovered = false,
  isAdmin = false,
  disablePopperPortal = false,
  showExposedActions = false,
  hideDropdownOnShowExposedActions = false,
  view
}: CellActionProp) => {
  const actionItems = useMemo(
    () =>
      actions
        .map((action) => ({
          id: action.id,
          label: action.name,
          onClick: () => onActionClick(action, row),
          icon: action.icon
            ? (props: Omit<IconProps, "name">) => (
                <Icon
                  // @ts-ignore
                  name={action.icon}
                  {...props}
                />
              )
            : undefined,
          iconName: action.icon,
          action: {
            type: action.type,
            requireConfirmation: action.requireConfirmation
          },
          hasDivider: action.hasDivider,
          linkToOpenInNewTab: isAdmin ? action.actionURL : undefined,
          isHidden: action.isHidden,
          isExposed: action.isExposed,
          isAdminAction: !!action.action_properties?.isAdminAction
        }))
        .filter((action) => (isAdmin ? true : !action.isAdminAction)),
    [actions, onActionClick, row, isAdmin]
  );

  const exposedActions = useMemo(() => {
    if (!showExposedActions) return [];

    return actionItems.filter((action) => action.isExposed);
  }, [actionItems, showExposedActions]);

  return (
    <div className={className} onClick={(e) => e.stopPropagation()} data-testid="GridActions">
      <div
        className={clsx(
          showExposedActions && "flex items-center rounded p-[1px]",
          view === ViewOption.GRID ? "gap-x-0.5" : "gap-x-2"
        )}
      >
        {exposedActions.map((action) => (
          <Tooltip key={action.id} title={action.label}>
            <IconButton
              color={view === ViewOption.GRID ? IconButtonColor.TRANSPARENT : IconButtonColor.WHITE}
              key={action.id}
              iconName={action.iconName}
              className={clsx(
                "bg-background",
                showOnlyInHovered && !isHovered && "hidden",
                view === ViewOption.GRID && "datagrid-row-exposed-actions"
              )}
              onClick={action.onClick}
            />
          </Tooltip>
        ))}
        {!!(!showExposedActions || (showExposedActions && !hideDropdownOnShowExposedActions)) && (
          <Dropdown
            MenuButton={<IconButton icon={MoreHorizontalIcon} {...iconProps} size="sm" />}
            items={actionItems}
            hidePortal={hidePortal}
            isHovered={isHovered}
            showOnlyInHovered={showOnlyInHovered}
            disablePopperPortal={disablePopperPortal}
          />
        )}
      </div>
    </div>
  );
};

export default CellAction;
